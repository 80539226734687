import { Controller } from "@hotwired/stimulus";
import Sortable from "sortablejs";

// Connects to data-controller="editor-footer-form-nav-item"
export default class extends Controller {
  static targets = ["itemsContainer", "item", "form"];

  itemsContainerTargetConnected(itemsContainer) {
    new Sortable(itemsContainer, {
      animation: 150,
      forceFallback: true,
      fallbackOnBody: true,
      handle: ".nav-item--handle",
      ghostClass: "nav-item--sortable-ghost",
      onSort: () => {
        this.setPositionValues();
        this.formTarget.requestSubmit();
      },
    });
  }

  setPositionValues() {
    this.itemTargets.forEach((item, index) => {
      item.querySelector("[data-field-name='position']").value = index + 1;
    });
  }
}
