import { Application } from "@hotwired/stimulus";

const application = Application.start();

// Configure Stimulus development experience
application.debug = false;
window.Stimulus = application;

export { application };

import Dropdown from "@stimulus-components/dropdown";
application.register("dropdown", Dropdown);

import Dialog from "@stimulus-components/dialog";
application.register("dialog", Dialog);

// Consumer controllers
import NavigationController from "../consumer/controllers/navigation_controller";
application.register("navigation", NavigationController);

import NavigationDropdownController from "../consumer/controllers/navigation_dropdown_controller";
application.register("navigation-dropdown", NavigationDropdownController);
