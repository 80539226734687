import { Controller } from "@hotwired/stimulus";
// Keep as reference

// Connects to data-controller="nested-form"
export default class extends Controller {
  static targets = ["insertBeforeThis", "template"];

  static values = {
    wrapperSelector: {
      type: String,
      default: ".nested-form-wrapper",
    },
  };

  add(e, newRecordId, templateTarget = this.templateTarget) {
    e.preventDefault();

    const content = templateTarget.innerHTML.replace(new RegExp(newRecordId, "g"), new Date().getTime().toString());
    this.insertBeforeThisTarget.insertAdjacentHTML("beforebegin", content);

    // const event = new CustomEvent("rails-nested-form:add", { bubbles: true });
    // this.element.dispatchEvent(event);
  }

  remove(e) {
    e.preventDefault();

    const wrapper = e.target.closest(this.wrapperSelectorValue);

    if (wrapper.dataset.newRecord === "true") {
      wrapper.remove();
    } else {
      wrapper.style.display = "none";

      const input = wrapper.querySelector("input[name*='_destroy']");
      input.value = "1";
    }

    // const event = new CustomEvent("rails-nested-form:remove", {
    //   bubbles: true,
    // });
    // this.element.dispatchEvent(event);
  }
}
