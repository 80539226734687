import AutoSubmit from "@stimulus-components/auto-submit";

// Connects to data-controller="auto-submit"
export default class extends AutoSubmit {
  static values = {
    delay: {
      type: Number,
      default: 200,
    },
  };

  initialize() {
    super.initialize();
  }

  connect() {
    super.connect();
  }

  submit() {
    super.submit();
  }
}
