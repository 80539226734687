import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="editor-accordion"
export default class extends Controller {
  static targets = ["chevron", "content"];
  connect() {}

  toggle(e) {
    const button = e.currentTarget;
    button.ariaExpanded = button.ariaExpanded === "false" ? "true" : "false";

    this.contentTarget.hidden = !this.contentTarget.hidden;
    this.chevronTarget.classList.add("duration-200");
    this.chevronTarget.classList.toggle("rotate-180");
  }
}

// <div data-controller="editor-accordion">
//   <button
//     type="button"
//     aria-expanded="false"
//     aria-controls="controls-1234"
//     id="accordion-1234"
//     data-action="editor-accordion#toggle"
//     class="bg-white w-full flex items-center justify-between px-5 py-3 hover:bg-neutral-100 focus:bg-neutral-100">
//     <span>Accordion example button</span>
//     <div class="">
//       <svg data-editor-accordion-target="chevron" class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-chevron-up"><path d="m18 15-6-6-6 6" /></svg>
//     </div>
//   </button>
//   <div id="controls-1234" aria-labelledby="accordion-1234" hidden class="px-5 pt-2" data-editor-accordion-target="content">
//     <p>Accordion example content</p>
//   </div>
// </div>
