import { Controller } from "@hotwired/stimulus";
import { post } from "@rails/request.js";
import { enter, leave } from "el-transition";
import tippy from "tippy.js";

// Connects to data-controller="editor"
const PANEL_POSITION_VALUE = "PANEL_POSITION_VALUE";
const PREVIEW_VIEWPORT_VALUE = "PREVIEW_VIEWPORT_VALUE";
let timeout;

export default class extends Controller {
  // TODO: add appropriate aria attributes and roles
  static targets = [
    "header",
    "loadingSpinner",
    "siteHeader",
    "mainContainer",
    "panelContainer",
    "panelLeftButton",
    "panelRightButton",
    "panelToggleButton",
    "previewInnerContainer",
    "previewInnerContainerBody",
    "turboFrameForm",
    "editorContainer",
    "savingStatus",
    "savedStatus",
    "desktopButton",
    "mobileButton",
    "contentPanel",
    "themePanel",
    "settingsPanel",
    "contentTab",
    "themeTab",
    "settingsTab",
  ];

  static values = {
    headerAlignment: String,
    updateNavLogoWidthUrl: String,
    activeTurboFrame: String,
    panelPosition: String,
    isPanelHidden: { type: Boolean, default: true },
    viewport: {
      type: String,
      default: "desktop",
    },
    loadingType: String,
    panelTab: String,
  };

  connect() {
    // Prevent editor panel/preview container multiple scrolls
    document.body.classList.add("overflow-y-hidden");

    // Set default right panel transition
    this.setDefaultPanelTransitions();

    // Set preferred panel position from local storage
    const panelPositionValue = localStorage.getItem(PANEL_POSITION_VALUE);
    if (panelPositionValue && panelPositionValue == "left") {
      this.panelPositionValue = "left";
    }
    // Set last preview viewport from local storage
    const previewViewportValue = localStorage.getItem(PREVIEW_VIEWPORT_VALUE);
    if (previewViewportValue && previewViewportValue === "mobile") {
      this.viewportValue = "mobile";
    }

    // Fix glitch when panel position is set from local storage, sets the position before showing this element
    this.element.classList.remove("invisible");

    // Event listeners
    this.boundOnInputChange = this.onInputChange.bind(this);
    document.addEventListener("input", this.boundOnInputChange);

    this.boundOnSortChange = this.onSortChange.bind(this);
    document.addEventListener("sort", this.boundOnSortChange);

    this.boundOnClickChange = this.onClickChange.bind(this);
    document.addEventListener("click", this.boundOnClickChange);

    // Tooltips!
    const tooltips = [
      {
        target: "#toggle-panel-visibility-top-button",
        content: "Toggle panel",
      },
      {
        target: "#toggle-desktop-viewport",
        content: "Desktop view",
      },
      {
        target: "#toggle-mobile-viewport",
        content: "Mobile view",
      },
      {
        target: "#toggle-panel-position-left",
        content: "Move panel to the left",
      },
      {
        target: "#toggle-panel-position-right",
        content: "Move panel to the right",
      },
      {
        target: "#live-site",
        content: "View Live Site",
      },
      {
        target: "#new-page",
        content: "New Page",
      },
      {
        target: "#page-settings",
        content: "Page Settings",
      },
    ];

    tooltips.forEach((tooltip) => {
      tippy(tooltip.target, {
        content: tooltip.content,
        delay: [300, 100],
        animation: false,
        placement: "bottom",
      });
    });
  }

  disconnect() {
    document.removeEventListener("input", this.boundOnInputChange);
    document.removeEventListener("sort", this.boundOnSortChange);
    document.removeEventListener("click", this.boundOnClick);
  }

  onClickChange(event) {
    // Disable clicks inside preview container
    if (this.previewInnerContainerBodyTarget.contains(event.target)) {
      if (event.target.closest("a")) {
        event.preventDefault();
      }
    }
  }

  onInputChange(e) {
    if (e.target.dataset.preventSave !== "true") {
      this.toggleSaveStatuses();
    }
  }

  onSortChange() {
    this.toggleSaveStatuses();
  }

  toggleSaveStatuses() {
    this.savingStatusTarget.classList.remove("hidden");
    this.savedStatusTarget.classList.add("hidden");
  }

  headerTargetConnected(e) {
    this.toggleViewportButtons();
  }

  handleHeaderAlignmentChange(e) {
    this.headerAlignmentValue = e.target.value;
  }

  siteHeaderTargetConnected() {
    // Set header_logo_width value and update editor preview
    if (this.headerAlignmentValue === "header_between") {
      const desktopNavItemContainer = document.getElementById("desktop-nav-item-list");
      const desktopLogo = document.getElementById("desktop-nav-logo");

      if (desktopNavItemContainer && desktopLogo) {
        let totalWidth = 0;
        let splitPointIndex;

        // Find nav items after split point and find their total width
        desktopNavItemContainer.querySelectorAll("[data-role='desktop-nav-item']").forEach((item, index) => {
          const isSplitPoint = item.dataset.splitPoint === "true";
          if (isSplitPoint) {
            splitPointIndex = index;
          }

          const itemsAfterSplitPoint = splitPointIndex && splitPointIndex < index;
          if (itemsAfterSplitPoint) {
            totalWidth += item.offsetWidth;
          }
        });

        // Do not set if 0
        if (totalWidth === 0) {
          return;
        }

        // Set logo width
        desktopLogo.style.minWidth = `${totalWidth}px`;

        // Save value
        post(this.updateNavLogoWidthUrlValue, {
          contentType: "application/json",
          body: { header_logo_width: totalWidth },
        });
      }
    }
  }

  handleTab(e) {
    this.panelTabValue = e.currentTarget.dataset.tabName;
  }

  panelTabValueChanged(value) {
    const CONTENT_TAB_NAME = "contentTab";
    const THEME_TAB_NAME = "themeTab";
    const SETTINGS_TAB_NAME = "settingsTab";

    if (value == CONTENT_TAB_NAME) {
      this.contentPanelTarget.classList.remove("hidden");
      this.themePanelTarget.classList.add("hidden");
      this.settingsPanelTarget.classList.add("hidden");
      this.themeTabTarget.classList.remove("editor__tab--active");
      this.settingsTabTarget.classList.remove("editor__tab--active");
      this.contentTabTarget.classList.add("editor__tab--active");
    } else if (value == THEME_TAB_NAME) {
      this.contentPanelTarget.classList.add("hidden");
      this.themePanelTarget.classList.remove("hidden");
      this.settingsPanelTarget.classList.add("hidden");
      this.contentTabTarget.classList.remove("editor__tab--active");
      this.settingsTabTarget.classList.remove("editor__tab--active");
      this.themeTabTarget.classList.add("editor__tab--active");
    } else if (value == SETTINGS_TAB_NAME) {
      this.contentPanelTarget.classList.add("hidden");
      this.themePanelTarget.classList.add("hidden");
      this.settingsPanelTarget.classList.remove("hidden");
      this.contentTabTarget.classList.remove("editor__tab--active");
      this.themeTabTarget.classList.remove("editor__tab--active");
      this.settingsTabTarget.classList.add("editor__tab--active");
    }
  }

  toggleViewport(e) {
    this.viewportValue = e.target.closest("button").dataset.viewport;
    localStorage.setItem(PREVIEW_VIEWPORT_VALUE, this.viewportValue);
  }

  viewportValueChanged(e) {
    this.toggleViewportButtons();
  }

  toggleViewportButtons() {
    if (this.viewportValue == "mobile") {
      this.previewInnerContainerTarget.classList.remove("max-width-shrink");
      this.previewInnerContainerTarget.classList.add("max-width-grow");
      this.desktopButtonTarget.classList.remove("editor__toggle-button--active");
      this.mobileButtonTarget.classList.add("editor__toggle-button--active");
    } else if (this.viewportValue == "desktop") {
      this.previewInnerContainerTarget.classList.remove("max-width-grow");
      this.previewInnerContainerTarget.classList.add("max-width-shrink");
      this.desktopButtonTarget.classList.add("editor__toggle-button--active");
      this.mobileButtonTarget.classList.remove("editor__toggle-button--active");
    }
  }
  savedStatusTargetConnected(e) {
    if (!e.classList.contains("hidden")) {
      this.savedStatusTarget.classList.add("duration-300");

      clearTimeout(timeout);
      timeout = setTimeout(() => {
        this.savedStatusTarget.classList.add("opacity-0");
        setTimeout(() => this.savedStatusTarget.classList.add("hidden"), 100);
      }, 3000);
    }
  }

  setDefaultPanelTransitions() {
    this.panelContainerTarget.dataset.transitionEnter = "transition-all ease-out mr-0";
    this.panelContainerTarget.dataset.transitionEnterStart = "mr-[-350px]";
    this.panelContainerTarget.dataset.transitionEnterEnd = "mr-0";
    this.panelContainerTarget.dataset.transitionLeave = "transition-all ease-out";
    this.panelContainerTarget.dataset.transitionLeaveStart = "mr-0";
    this.panelContainerTarget.dataset.transitionLeaveEnd = "mr-[-350px]";
  }

  togglePanelVisibility(e) {
    this.isPanelHiddenValue = !this.isPanelHiddenValue;

    if (this.panelPositionValue === "left") {
      this.panelContainerTarget.dataset.transitionEnter = "transition-all ease-out ml-0";
      this.panelContainerTarget.dataset.transitionEnterStart = "ml-[-350px]";
      this.panelContainerTarget.dataset.transitionEnterEnd = "ml-0";
      this.panelContainerTarget.dataset.transitionLeave = "transition-all ease-out";
      this.panelContainerTarget.dataset.transitionLeaveStart = "ml-0";
      this.panelContainerTarget.dataset.transitionLeaveEnd = "ml-[-350px]";
    } else if (this.panelPositionValue === "right") {
      this.setDefaultPanelTransitions();
    }

    if (this.isPanelHiddenValue) {
      enter(this.panelContainerTarget);
    } else {
      leave(this.panelContainerTarget);
    }
  }

  activeTurboFrameValueChanged(value, previousValue) {
    if (value === "" && previousValue === "") return;

    const closeAllForms = value === "" && previousValue;
    if (closeAllForms) {
      this.element.querySelectorAll("turbo-frame[data-role='form-turbo-frame']").forEach((formTurboFrame) => {
        formTurboFrame.classList.remove("turbo-frame--active");
        formTurboFrame.innerHTML = "";
      });
      return;
    }

    console.log("[turbo-frame:previousValue]", previousValue);
    console.log("[turbo-frame:value]", value);

    // Open form
    this.mainContainerTarget.classList.remove("overflow-y-auto");
    const turboFrame = document.getElementById(value);
    turboFrame.classList.add("turbo-frame--active");

    // Fix glitch when removing "overflow-y-auto" and fixing dup scrolls, make sure to remove when disconnecting
    if (previousValue === "") {
      this.mainContainerTarget.classList.add("invisible");
    }

    // Close previous (explicitly declare which forms and when to close)
    let turboFrameIdsToClose = [];

    // Header Form
    if (previousValue === "editor:navigation-header-form-new" && value === "editor:navigation-header-form") {
      turboFrameIdsToClose.push("editor:navigation-header-form-new");
    }

    if (previousValue === "editor:navigation-header-form-record" && value === "editor:navigation-header-form") {
      turboFrameIdsToClose.push("editor:navigation-header-form-new");
      turboFrameIdsToClose.push("editor:navigation-header-form-record");
    }

    if (previousValue === "editor:navigation-header-form-new-child" && value === "editor:navigation-header-form-record") {
      turboFrameIdsToClose.push("editor:navigation-header-form-new-child");
    }

    if (previousValue === "editor:navigation-header-form-record-child" && value === "editor:navigation-header-form-record") {
      turboFrameIdsToClose.push("editor:navigation-header-form-new-child");
      turboFrameIdsToClose.push("editor:navigation-header-form-record-child");
    }

    if (previousValue === "editor:navigation-header-form-new-child" && value === "editor:navigation-header-form") {
      turboFrameIdsToClose.push("editor:navigation-header-form-new-child");
      turboFrameIdsToClose.push("editor:navigation-header-form-record-child");
      turboFrameIdsToClose.push("editor:navigation-header-form-record");
    }

    if (previousValue === "editor:navigation-header-form-record-child" && value === "editor:navigation-header-form") {
      turboFrameIdsToClose.push("editor:navigation-header-form-new-child");
      turboFrameIdsToClose.push("editor:navigation-header-form-record-child");
      turboFrameIdsToClose.push("editor:navigation-header-form-record");
    }

    // Footer Form
    if (previousValue === "editor:navigation-footer-form-new" && value === "editor:navigation-footer-form") {
      turboFrameIdsToClose.push("editor:navigation-footer-form-new");
    }

    if (previousValue === "editor:navigation-footer-form-record" && value === "editor:navigation-footer-form") {
      turboFrameIdsToClose.push("editor:navigation-footer-form-new");
      turboFrameIdsToClose.push("editor:navigation-footer-form-record");
    }

    if (previousValue === "editor:navigation-footer-form-new-child" && value === "editor:navigation-footer-form-record") {
      turboFrameIdsToClose.push("editor:navigation-footer-form-new-child");
    }

    if (previousValue === "editor:navigation-footer-form-record-child" && value === "editor:navigation-footer-form-record") {
      turboFrameIdsToClose.push("editor:navigation-footer-form-new-child");
      turboFrameIdsToClose.push("editor:navigation-footer-form-record-child");
    }

    if (previousValue === "editor:navigation-footer-form-new-child" && value === "editor:navigation-footer-form") {
      turboFrameIdsToClose.push("editor:navigation-footer-form-new-child");
      turboFrameIdsToClose.push("editor:navigation-footer-form-record-child");
      turboFrameIdsToClose.push("editor:navigation-footer-form-record");
    }

    if (previousValue === "editor:navigation-footer-form-record-child" && value === "editor:navigation-footer-form") {
      turboFrameIdsToClose.push("editor:navigation-footer-form-new-child");
      turboFrameIdsToClose.push("editor:navigation-footer-form-record-child");
      turboFrameIdsToClose.push("editor:navigation-footer-form-record");
    }

    //
    turboFrameIdsToClose.forEach((formId) => {
      const prevTurboFrame = document.getElementById(formId);
      prevTurboFrame.classList.remove("turbo-frame--active");
      requestAnimationFrame(() => {
        prevTurboFrame.innerHTML = "";
      });
    });
  }

  togglePanelPosition(e) {
    this.panelPositionValue = e.target.closest("button").dataset.panelPosition;
    localStorage.setItem(PANEL_POSITION_VALUE, this.panelPositionValue);
  }

  panelPositionValueChanged(e) {
    this.editorContainerTarget.classList.toggle("flex-row-reverse", e === "left");
  }

  openForm(event) {
    this.loadingSpinnerTarget.classList.remove("hidden");
    this.activeTurboFrameValue = event.currentTarget.dataset.formTurboFrameId;
    post(event.currentTarget.dataset.formUrl, { responseKind: "turbo-stream" });
  }

  closeAllForms() {
    this.activeTurboFrameValue = "";
  }

  closeForm(event) {
    if (event.type == "submit" && event.submitter.value !== "Delete") {
      // Show spinner when closing form with submission
      this.loadingSpinnerTarget.classList.remove("hidden");
    }
    this.activeTurboFrameValue = event.currentTarget.dataset.formTurboFrameId;
  }

  selectPage(event) {
    this.element.querySelectorAll("[data-is-current-page]").forEach((button) => {
      button.dataset.isCurrentPage = button.dataset.pageId == event.currentTarget.dataset.pageId;
    });
  }

  turboFrameFormTargetConnected(event) {
    requestAnimationFrame(() => {
      event.classList.add("turbo-frame-form--active-slide-in");
      this.loadingSpinnerTarget.classList.add("hidden");
    });

    // Focus targets marked with data-focus-first-input="true"
    if (event.dataset.focusFirstInput === "true") {
      setTimeout(() => {
        const firstTextInput = event.querySelector("input[type='text']");
        if (firstTextInput) {
          firstTextInput.focus();
          firstTextInput.setSelectionRange(firstTextInput.value.length, firstTextInput.value.length);
        }
      }, 150);
    }
  }

  turboFrameFormTargetDisconnected(event) {
    // Fix glitch when removing "overflow-y-auto" and fixing dup scrolls, make sure to remove when disconnecting
    this.mainContainerTarget.classList.remove("invisible");

    requestAnimationFrame(() => {
      if (this.turboFrameFormTargets.length == 0) {
        // Reset scroll position when all forms are closed and prevent multiple scrolls
        this.mainContainerTarget.classList.add("overflow-y-auto");
        // this.mainContainerTarget.scrollTop = 0;
      } else {
        this.turboFrameFormTargets.forEach((formTarget) => {
          // Reset scroll position when form is closed
          // formTarget.scrollTop = 0;
        });
      }
    });
  }

  handlePublishState(event) {
    const target = event.currentTarget;
    target.firstElementChild.classList.remove("hidden");
  }
}
