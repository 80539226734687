import { Controller } from "@hotwired/stimulus";
import Sortable from "sortablejs";
import tippy from "tippy.js";

// Connects to data-controller="editor-header-form"
export default class extends Controller {
  static targets = ["itemsContainer", "item", "form"];

  connect() {
    tippy("#new-header-nav-item", {
      content: "Create header item",
      delay: [300, 100],
      animation: false,
      placement: "bottom",
    });
  }

  itemsContainerTargetConnected(itemsContainer) {
    new Sortable(itemsContainer, {
      animation: 150,
      forceFallback: true,
      fallbackOnBody: true,
      handle: ".nav-item--handle",
      ghostClass: "nav-item--sortable-ghost",
      onSort: () => {
        this.setPositionValues();
        this.formTarget.requestSubmit();
      },
    });
  }

  setPositionValues() {
    this.itemTargets.forEach((item, index) => {
      item.querySelector("[data-field-name='position']").value = index + 1;
    });
  }

  setSplitPointValues(e) {
    this.itemTargets.forEach((item, index) => {
      item.querySelector("[data-field-name='split_point']").value = item.querySelector("[data-field-name='id']").value === e.target.value;
    });
  }
}
