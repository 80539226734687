import { Controller } from "@hotwired/stimulus";
import Sortable from "sortablejs";
import tippy from "tippy.js";

// Connects to data-controller="editor-footer-form"
export default class extends Controller {
  static values = {
    footerStyleField: String,
  };
  static targets = ["itemsContainer", "item", "form", "footerStyleField"];

  connect() {
    tippy("#new-footer-nav-item", {
      content: "Create footer item",
      delay: [300, 100],
      animation: false,
      placement: "bottom",
    });
  }

  itemsContainerTargetConnected(itemsContainer) {
    new Sortable(itemsContainer, {
      animation: 150,
      forceFallback: true,
      fallbackOnBody: true,
      handle: ".nav-item--handle",
      ghostClass: "nav-item--sortable-ghost",
      onSort: () => {
        this.setPositionValues();
        this.formTarget.requestSubmit();
      },
    });
  }

  setPositionValues() {
    this.itemTargets.forEach((item, index) => {
      item.querySelector("[data-field-name='position']").value = index + 1;
    });
  }

  revertFooterStyleFieldValue() {
    this.footerStyleFieldTarget.value = this.footerStyleFieldValue;
  }
}
