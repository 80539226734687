import { Controller } from "@hotwired/stimulus";
// Keep as reference

// Connects to data-controller="editor-panel"
export default class extends Controller {
  static targets = [
    "blocksTabButton",
    "blocksTabContainer",
    "pageTabButton",
    "pageTabContainer",
    "navigationTabContainer",
    "navigationTabButton",
    "navigationHeaderSubTabButton",
    "navigationFooterSubTabButton",
    "navigationHeaderSubTabContainer",
    "navigationFooterSubTabContainer",
  ];
  static values = {
    tabName: String,
    navigationSubTabName: String,
  };

  handleTabClick(e) {
    this.tabNameValue = e.target.dataset.tabName;
  }

  handleNavigationTabClick(e) {
    this.navigationSubTabNameValue = e.currentTarget.dataset.tabName;
  }

  navigationSubTabNameValueChanged(value) {
    const NAVIGATION_HEADER_SUB_TAB_NAME = "navigation-header-sub-tab";
    const NAVIGATION_FOOTER_SUB_TAB_NAME = "navigation-footer-sub-tab";

    if (value == NAVIGATION_HEADER_SUB_TAB_NAME) {
      this.navigationHeaderSubTabContainerTarget.hidden = false;
      this.navigationFooterSubTabContainerTarget.hidden = true;
      this.navigationHeaderSubTabButtonTarget.classList.add("border-sky-700");
      this.navigationFooterSubTabButtonTarget.classList.remove("border-sky-700");
    } else if (value == NAVIGATION_FOOTER_SUB_TAB_NAME) {
      this.navigationHeaderSubTabContainerTarget.hidden = true;
      this.navigationFooterSubTabContainerTarget.hidden = false;
      this.navigationFooterSubTabButtonTarget.classList.add("border-sky-700");
      this.navigationHeaderSubTabButtonTarget.classList.remove("border-sky-700");
    }
  }

  tabNameValueChanged(value) {
    const PAGE_TAB_NAME = "page-tab";
    const NAVIGATION_TAB_NAME = "navigation-tab";
    const BLOCKS_TAB_NAME = "blocks-tab";

    if (value == PAGE_TAB_NAME) {
      this.pageTabContainerTarget.hidden = false;
      this.pageTabButtonTarget.classList.add("border-b-2");

      this.blocksTabContainerTarget.hidden = true;
      this.blocksTabButtonTarget.classList.remove("border-b-2");
      this.navigationTabContainerTarget.hidden = true;
      this.navigationTabButtonTarget.classList.remove("border-b-2");
    } else if (value == NAVIGATION_TAB_NAME) {
      this.navigationTabContainerTarget.hidden = false;
      this.navigationTabButtonTarget.classList.add("border-b-2");

      this.pageTabContainerTarget.hidden = true;
      this.pageTabButtonTarget.classList.remove("border-b-2");
      this.blocksTabContainerTarget.hidden = true;
      this.blocksTabButtonTarget.classList.remove("border-b-2");
    } else if (value == BLOCKS_TAB_NAME) {
      this.blocksTabContainerTarget.hidden = false;
      this.blocksTabButtonTarget.classList.add("border-b-2");

      this.navigationTabContainerTarget.hidden = true;
      this.navigationTabButtonTarget.classList.remove("border-b-2");
      this.pageTabContainerTarget.hidden = true;
      this.pageTabButtonTarget.classList.remove("border-b-2");
    }
  }
}
