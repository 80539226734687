import { Controller } from "@hotwired/stimulus";
import { post } from "@rails/request.js";
import Sortable from "sortablejs";

// Connects to data-controller="sections-form"
export default class extends Controller {
  static targets = ["itemsContainer", "item", "form"];

  static values = {
    url: String,
  };

  itemsContainerTargetConnected(itemsContainer) {
    new Sortable(itemsContainer, {
      animation: 150,
      forceFallback: true,
      fallbackOnBody: true,
      handle: ".nav-item--handle",
      ghostClass: "nav-item--sortable-ghost",
      onSort: () => {
        this.setPositionValues();
        this.formTarget.requestSubmit();
      },
    });
  }

  setPositionValues() {
    this.itemTargets.forEach((item, index) => {
      item.querySelector("[data-field-name='position']").value = index + 1;
    });
  }

  async submit() {
    await post(this.urlValue);

    // After creating a hero section, change the active turbo frame value to trigger a form open via
    // editor_controller.js#activeTurboFrameValueChanged

    const heroSectionTurboFrameID = "editor:section-form"
    document.querySelector('[data-controller="editor"]').setAttribute("data-editor-active-turbo-frame-value", heroSectionTurboFrameID)
  }
}
