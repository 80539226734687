import { Controller } from "@hotwired/stimulus";

// Based on https://github.com/tailwindlabs/tailwindcss-container-queries?tab=readme-ov-file#configuration
const MOBILE_BREAK_POINT = 1024; // 5xl
const FOOTER_BREAK_POINT = 768; // 3xl

// Connects to data-controller="container"
export default class extends Controller {
  connect() {
    this.resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const { width } = entry.contentRect;
        const desktopViewport = width > MOBILE_BREAK_POINT;
        let viewportName = "mobile";

        if (desktopViewport) {
          viewportName = "desktop";
        } else if (width > FOOTER_BREAK_POINT) {
          viewportName = "tablet";
        }
        this.element.dataset.viewportName = viewportName;

        // Handle mobile nav scroll overflow toggle in editor
        const mobileNavToggle = document.getElementById("mobile-nav-toggle");
        const editorPreviewContainerBody = document.getElementById("preview-inner-container-body");
        if (desktopViewport) {
          editorPreviewContainerBody.classList.remove("!overflow-hidden");
        } else {
          if (mobileNavToggle.ariaExpanded == "true") {
            editorPreviewContainerBody.classList.add("!overflow-hidden");
          }
        }
      }
    });

    // Observe the element this controller is attached to
    this.resizeObserver.observe(this.element);
  }

  disconnect() {
    // Clean up the observer when the controller is disconnected
    if (this.resizeObserver) {
      this.resizeObserver.unobserve(this.element);
      this.resizeObserver.disconnect();
    }
  }
}
